import { SearchQuery } from "@/interfaces/httpQuery";
import { Vendor } from "@/interfaces/vendor";
import { i18n } from "@/plugins/i18n";
import { messagesService } from "@/services/messages.service";
import { TableSuccessModalResponse } from "helix-vue-components";
import HttpService from "./http.service";

// tslint:disable-next-line
const toFormData = require("object-to-formdata");
const removeEmptyNotesOrContacts = (vendor: Vendor) => {
  if (vendor.notes && vendor.notes.length && !vendor.notes[0].note) {
    delete vendor.notes;
  }
  if (vendor.contacts && vendor.contacts.length && !vendor.contacts[0].name) {
    delete vendor.contacts;
  }
  return vendor;
};
class VendorService extends HttpService {
  protected uri: string = "catalog/vendors";
  protected loadDispatcher: string = "VendorModule/loadVendors";
  protected searchableField: SearchQuery[] = [
    {
      field: "name",
      type: "contains"
    }
  ];
  protected emptySearchSet: Vendor[] = [];

  public setQuery(query?: object | null) {
    if (query) {
      delete this.query["q[is_active]"];
      delete this.query["q[is_active_is_false]"];
    }
    this.query = { ...this.query, ...query };
  }

  public async getVendors(
    getAll: boolean = false,
    query: object = {}
  ): Promise<Vendor[]> {
    if (getAll) {
      delete this.query["q[name_contains]"];
    }

    const response = await super.get({ ...this.query, ...query });
    return response.data.data || response.data;
  }

  public async removeVendor(vendor: Vendor) {
    const response = await super.disable(vendor);
    return response.data;
  }

  /**
   * Creates a new vendor.
   * @param vendor: Vendor
   * @returns Promise<Vendor | void>
   */
  public async addVendor(vendor: Vendor): Promise<Vendor | void> {
    try {
      const auxUri = this.uri;
      vendor = removeEmptyNotesOrContacts(vendor);
      if (vendor.attachments && !vendor.attachments.length) {
        delete vendor.attachments;
      }

      const response = await super.post(vendor);

      if (vendor.attachments && vendor.attachments.length) {
        response.attachments = vendor.attachments;
        await this.saveFiles(response);
      }

      return response.data;
    } catch (e) {
      if (e.response === undefined) {
        messagesService.renderErrorMessage(i18n.t("template_server_error"));
      } else {
        messagesService.renderErrorMessage(e);
      }
    } finally {
      this.uri = "catalog/vendors";
    }
  }

  public async getVendor(vendorId: string) {
    const response = await super.findById(vendorId);
    return response;
  }

  public async search(value: string = ""): Promise<Vendor[]> {
    if (!value && this.emptySearchSet.length) {
      return this.emptySearchSet;
    }
    const response = await super.get({ "q[name_contains]": value });
    if (!value) {
      this.emptySearchSet = response.data.data;
    }
    return response.data.data;
  }

  /**
   * Retrieves files list for specific vendor.
   * @param vendorId: string
   * @returns: string;
   */
  public async getFiles(vendorId: string) {
    try {
      this.uri = `catalog/vendors/${vendorId}/documents`;
      const response = await super.get();
      return response.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  /**
   * Updates vendor
   * @param vendor: Vendor
   * @param deletedFiles: any[]
   * @returns Promise<Vendor | void>
   */
  public async updateVendor(
    vendor: Vendor,
    deletedFiles: number[]
  ): Promise<Vendor | void> {
    try {
      this.uri = `catalog/vendors`;

      vendor = removeEmptyNotesOrContacts(vendor);

      const response = super.put(vendor, vendor);

      if (deletedFiles && deletedFiles.length) {
        await this.deleteFiles(`${vendor.id}`, deletedFiles);
      }

      if (vendor.attachments && vendor.attachments.length) {
        await this.saveFiles(vendor);
      }
      return response;
    } catch (e) {
      if (e.response === undefined) {
        messagesService.renderErrorMessage(i18n.t("template_server_error"));
      } else {
        messagesService.renderErrorMessage(e);
      }
    } finally {
      this.uri = "catalog/vendors";
    }
  }

  /**
   * Save attached files to documents endpoints.
   * @param vendor
   * @returns object
   */
  public async saveFiles(vendor: Vendor): Promise<object | void> {
    try {
      this.uri = `catalog/vendors/${vendor.id}/documents`;

      let response;

      vendor.attachments!.forEach(async attachment => {
        const data = toFormData(attachment, { indices: true });
        response = await super.post(data);
      });

      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      this.uri = "catalog/vendors";
    }
  }

  public async toggleVendor(model: TableSuccessModalResponse): Promise<Vendor> {
    if (model.item.is_active) {
      return await this.disable(model.item);
    }
    return await this.enable(model.item);
  }

  /**
   * Save attached files to documents endpoints.
   * @param vendor
   * @returns object
   */
  public async deleteFiles(
    vendorID: string,
    fileIDs: number[]
  ): Promise<object | void> {
    try {
      let response;
      this.uri = `catalog/vendors/${vendorID}/documents`;

      fileIDs.forEach(async id => {
        response = await super.delete({ id });
      });

      messagesService.renderSuccessMessage("deleted_successfully");

      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      this.uri = "catalog/vendors";
    }
  }

  public async setURI(vendorId: number) {
    this.uri = `catalog/vendors/${vendorId}/documents`;
  }
  public async setDefaultURI() {
    this.uri = "catalog/vendors";
  }
}

export const vendorService: VendorService = new VendorService();
