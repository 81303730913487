import {
  DiscountValues,
  loyaltyDiscountsCodes,
  overwriteDiscountCodes
} from "@/enums/discountManager";
import { currencyFilter } from "@/filters/currency.filter";
import { Discount } from "@/interfaces/discount";
import {
  LoyalityItem,
  Order,
  OrderItem,
  OrderPayments,
  RefundHistory,
  statusOrder,
  VoidOrderResponse
} from "@/interfaces/order";
import { PreOrder } from "@/interfaces/orderFulfillment";
import { PrescriptionDetails } from "@/interfaces/prescriptionDetails";
import { store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { SecurityPinService } from "@/plugins/security-pin/security-pin.service";
import HttpService from "@/services/http.service";
import { messagesService } from "@/services/messages.service";
import { truncate } from "@/utils/math.utils";
import { AxiosResponse } from "axios";
import { TablePagination } from "helix-vue-components";
import Vue from "vue";

class OrderService extends HttpService {
  protected loadDispatcher: string = "OrderModule/loadOrders";
  protected uri: string = "sale/orders";
  protected mainEmbed =
    "orderPayments.paymentMethod,customer,user,orderItems.loyaltyItem.programTier,orderItems.loyaltyItem.programTier.loyaltyProgram";
  protected defaultSort: string = "id";
  constructor() {
    super();
  }

  public async get(query: object | null = null): Promise<Order[]> {
    this.uri = "/sale/orders";
    if (query) {
      this.query = {
        embed: this.mainEmbed,
        page: this.query.page,
        per_page: this.query.per_page,
        sort: this.query.sort,
        ...query
      };
    }
    const response = await super.get();
    return response.data.data;
  }

  public authorizeOverride(): Promise<any> {
    const pin$ = new SecurityPinService();
    return new Promise((resolve, reject) => {
      pin$
        .ensure(
          String(i18n.t("security_pin.title")),
          String(i18n.t("limit.pin_overrride"))
        )
        .then(
          async pin => {
            this.uri = `${process.env.VUE_APP_CORE_URL}/permission/authorize`;
            const payload = {
              action: "process_limits_with_warnings",
              resource: "sale.orders",
              service: "bdi",
              pin_code: String(pin)
            };
            await super.post(payload, false).then(
              response => {
                this.uri = "sale/orders";
                resolve(pin);
              },
              e => {
                this.uri = "sale/orders";
                messagesService.showMessage(
                  "fas fa-exclamation-circle",
                  e.response.data.error.message,
                  "error"
                );
              }
            );
          },
          () => {
            messagesService.showMessage(
              "fas fa-exclamation-circle",
              String(i18n.t("security_pin.required_message")),
              "error"
            );
            reject();
          }
        );
    });
  }

  public async updatePrescriptionDetails(
    prescriptionDetails: PrescriptionDetails
  ) {
    this.uri = `sale/orders/prescription/${prescriptionDetails.order_item_id}`;
    try {
      const response: AxiosResponse = await Vue.axios({
        method: "PUT",
        url: this.uri,
        data: prescriptionDetails
      });
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  public async updatePreOrderPrescriptionDetails(
    prescriptionDetails: PrescriptionDetails,
    itemUid: string | null
  ) {
    this.uri = `public/v1/preorders/prescription/${itemUid}`;
    const preOrderPrescriptionPayload = {
      prescription_details: prescriptionDetails
    };
    try {
      const response: AxiosResponse = await Vue.axios({
        method: "PUT",
        url: this.uri,
        data: preOrderPrescriptionPayload
      });
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  public async getCustomerOrders(customerUID: string): Promise<Order[]> {
    this.uri = `/store/customers/${customerUID}/open_orders`;
    try {
      const query = {
        embed: "customer,preOrder"
      };
      const response = await super.get(query);
      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    } finally {
      delete this.query.status;
    }
  }

  public async getCaregiverPatientsOrders(
    customerUID: string
  ): Promise<Order[]> {
    this.uri = `/store/customers/${customerUID}/open_orders_patients`;
    try {
      const query = {
        embed: "customer,preOrder"
      };
      const response = await super.get(query);
      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    } finally {
      delete this.query.status;
    }
  }

  public async getOrdersPaid(customQuery?: any): Promise<Order[]> {
    this.uri = "sale/orders";
    try {
      const query = {
        ...(customQuery || this.query),
        sort: customQuery.sort || this.query.sort || "-sold_at",
        embed: `${this.mainEmbed},preOrder.items`,
        "q[status_is_in]": [
          statusOrder.paid,
          statusOrder.partiallyRefound,
          statusOrder.paidModified,
          statusOrder.refund
        ]
      };
      const response = await super.get(query);
      return response.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }

  public async getOrdersPaidOfCaregiverForPatients(
    customerId: string
  ): Promise<Order[]> {
    const uri = this.uri;
    this.uri = `store/customers/${customerId}/patients/orders`;
    const query = {
      ...this.query,
      per_page: 5
    };
    const response = await super.get(query);
    this.uri = uri;
    return response.data.data;
  }

  public async getOrdersPaidOfCustomer(
    id: string,
    pagination: TablePagination
  ) {
    this.uri = "/sale/orders";
    const query = {
      page: pagination.currentPage,
      per_page: pagination.itemsPerPage || 10,
      "q[status_is_in]": [
        statusOrder.paid,
        statusOrder.partiallyRefound,
        statusOrder.paidModified,
        statusOrder.refund
      ],
      embed: "location",
      customer_id: id,
      sort: "-created_at",
      source: "customer_history"
    };
    const response = await super.get(query);
    return response;
  }
  public async confirm(
    order: Order,
    orderPayment: object,
    caregiverId: string | null,
    pincode: string | null,
    soldAt: string | Date
  ): Promise<Order | null> {
    try {
      const headers = (pincode && { pincode }) || {};
      const retailSettings = store.getters["AuthModule/currentRetailSettings"];
      const payload: { [key: string]: any } = {
        order_payments: orderPayment,
        caregiver_id: caregiverId,
        applied_discounts: order.applied_discounts
      };
      if (soldAt && retailSettings.sales_time_specify) {
        payload.sold_at = soldAt;
      }
      const response: AxiosResponse = await Vue.axios({
        method: "POST",
        url: `/sale/orders/${order.id}/confirm`,
        headers,
        data: payload,
        params: {
          embed: "orderPayments.paymentMethod"
        }
      });
      localStorage.removeItem("order_saved");
      return response.data.data || response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async refundsVoid(
    id: number,
    pincode: string,
    hasMetrc: boolean
  ): Promise<VoidOrderResponse> {
    try {
      const response = await Vue.axios({
        method: "POST",
        url: `/sale/refunds/${id}/void`,
        headers: {
          Pincode: `${pincode}`
        }
      });
      messagesService.renderSuccessMessage("void_success");
      return {
        status: "success",
        order: response.data.data
      };
    } catch (e) {
      if (hasMetrc) {
        return {
          status: "error",
          errors: messagesService.parseMetrcError(e)
        };
      }
      messagesService.renderErrorMessage(e);
      return { status: "error" };
    }
  }

  public async void(
    id: number,
    pincode: string,
    hasMetrc: boolean
  ): Promise<VoidOrderResponse> {
    try {
      const response = await Vue.axios({
        method: "POST",
        url: `/sale/orders/${id}/void`,
        headers: {
          Pincode: `${pincode}`
        }
      });
      messagesService.renderSuccessMessage("void_success");
      return {
        status: "success",
        order: response.data.data
      };
    } catch (e) {
      if (hasMetrc) {
        return {
          status: "error",
          errors: messagesService.parseMetrcError(e)
        };
      }
      messagesService.renderErrorMessage(e);
      return { status: "error" };
    }
  }

  public async find(id: number, embed?: string): Promise<Order | null> {
    try {
      const response: AxiosResponse = await Vue.axios({
        method: "GET",
        url: `/sale/orders/${id}`,
        params: {
          embed:
            embed ||
            "orderPayments.paymentMethod,customer,orderItems.batch.batchPriceLevel,customer.servingNumber"
        }
      });
      return response.data.data as Order;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async findById(id: number | string, query?: object): Promise<any> {
    this.uri = "sale/orders";
    return super.findById(id, query);
  }

  public async getItemsRefunded(orderNumber: number): Promise<OrderItem[]> {
    const uri = (this.uri = this.uri);
    this.uri = `/sale/orders/${orderNumber}/refunds`;
    try {
      const response = await super.get({});
      this.uri = uri;
      return response.data.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      this.uri = uri;
      return [];
    }
  }

  // allows to search for the order in pos cart
  public async getOrdersRefund(
    orderNumber: string,
    customerId: number
  ): Promise<Order[] | null> {
    this.uri = `/sale/orders`;
    try {
      const query = {
        "q[order_number_eq]": orderNumber,
        "q[customer_id_equals]": customerId,
        "q[status_is_in]": [statusOrder.paid, statusOrder.partiallyRefound]
      };
      const response = await super.get(query);
      return response.data.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  // create and generate store credit

  public async refund(
    id: number,
    orderItems: OrderItem[],
    paymentMethods: any,
    showLoading: () => void
  ): Promise<RefundHistory | null> {
    const pinCode = await this.authorize();
    if (!pinCode) {
      return null;
    }

    const mayusPaymentMethods = Object.entries(paymentMethods as Record<
      string,
      unknown
    >).reduce(
      (acc, [key, value]) => {
        acc[(key as string).toUpperCase().replace(" ", "_")] = value;
        return acc;
      },
      {} as Record<string, unknown>
    );

    try {
      if (Object.keys(mayusPaymentMethods)[0] === "DEBITCARD") {
        mayusPaymentMethods.DEBIT_CARD = mayusPaymentMethods.DEBITCARD;
        delete mayusPaymentMethods.DEBITCARD;
      } else if (Object.keys(mayusPaymentMethods)[0] === "CREDITCARD") {
        mayusPaymentMethods.CREDIT_CARD = mayusPaymentMethods.CREDITCARD;
        delete mayusPaymentMethods.CREDITCARD;
      }
      showLoading();
      const response = await Vue.axios({
        method: "POST",
        url: `sale/orders/${id}/refund`,
        data: {
          order_items: orderItems,
          reason: "",
          payment_methods: mayusPaymentMethods
        },
        headers: {
          Pincode: pinCode
        }
      });

      messagesService.renderSuccessMessage(
        i18n.t("refund.message_refund").toString()
      );
      return response.data.data as RefundHistory;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async getBalanceCustomer(customerId: string) {
    try {
      const url = `/store/customers/${customerId}/current_balance`;
      const resp: AxiosResponse = await Vue.axios({
        method: "GET",
        url
      });
      return resp.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async refundInformation(
    orderItemId: number,
    orderItems: OrderItem[],
    validateQuantities: boolean = true
  ) {
    try {
      const url = `/sale/orders/${orderItemId}/refund_information`;
      const response: AxiosResponse = await Vue.axios({
        method: "POST",
        url,
        data: {
          validate_quantities: validateQuantities,
          order_items: orderItems
            .filter(item => item.available)
            .map(item => ({
              id: item.id,
              quantity: item.quantityToRefund || item.available
            }))
        }
      });
      return response.data.data.refund_breakdown_preview;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async refundConfig(orderItemId: number) {
    try {
      const uri = `/sale/orders/${orderItemId}/refund_config`;
      const response = await Vue.axios({
        method: "GET",
        url: uri
      });
      return response.data.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  public async saveOrder(orderId: number) {
    try {
      await Vue.axios({
        method: "PATCH",
        url: `/sale/orders/${orderId}/save`
      });
      messagesService.renderSuccessMessage(i18n.t("order_saved").toString());
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async restoreOrder(orderId: number) {
    try {
      await Vue.axios({
        method: "PATCH",
        url: `/sale/orders/${orderId}/restore`
      });
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async restorePreOrder(preOrderId: string): Promise<PreOrder | null> {
    try {
      const response = await Vue.axios({
        method: "PATCH",
        url: `/preorders/${preOrderId}/load`
      });
      return response.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async unloadPreorder(
    preOrderId: string,
    update: boolean = false
  ): Promise<PreOrder | null> {
    try {
      const response = await Vue.axios({
        method: "PATCH",
        url: `/preorders/${preOrderId}/unload`,
        data: { update }
      });
      return response.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async deletePreorder(preOrderId: string): Promise<PreOrder | null> {
    try {
      const response = await Vue.axios({
        method: "DELETE",
        url: `/preorders/${preOrderId}`
      });
      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public getUsableWeight(item: OrderItem) {
    if (item.product_info) {
      if (item.product_info.requires_weighing) {
        return `${item.quantity}${item.product_info.usable_weight_unit}`;
      } else if (item.product_info.usable_weight_value && item.quantity) {
        return `${item.quantity * item.product_info.usable_weight_value}${
          item.product_info.usable_weight_unit
        }`;
      }
    }
    return "--";
  }

  public parseOrder(order: Order) {
    // Order parser to send to backEnd just the needed info
    return {
      id: order.id || undefined,
      customer_id: order.customer_id,
      order_items: this.parseOrderItems(order.order_items),
      applied_discounts: order.applied_discounts,
      program_tier_discounts: order.program_tier_discounts,
      caregiver_id: order.caregiver_id
    };
  }

  public parseOrderItems(orderItems: OrderItem[] = []) {
    return orderItems.map((item: OrderItem) => {
      if (item._destroy) {
        return {
          order_item_uid: item.order_item_uid,
          _destroy: true
        };
      }
      if (item.prescription_details) {
        return {
          order_item_uid: item.order_item_uid,
          sku: item.batch_sku || item.sku,
          batch_barcode_uid: item.batch_barcode_uid,
          quantity: item.quantity,
          pharmacist_id: item.pharmacist_id || null,
          prescription_details: this.parsePrescriptionDetail(
            item.prescription_details
          )
        };
      }
      return {
        order_item_uid: item.order_item_uid,
        sku: item.batch_sku || item.sku,
        batch_barcode_uid: item.batch_barcode_uid,
        quantity: item.quantity
      };
    });
  }

  public parsePrescriptionDetail(prescriptionDetails: PrescriptionDetails) {
    const prescriptionData: PrescriptionDetails = {
      quantity: prescriptionDetails.quantity,
      quantity_unit_measure: prescriptionDetails.quantity_unit_measure,
      timeframe_unit_measure: prescriptionDetails.timeframe_unit_measure,
      dosage_unit_measure: prescriptionDetails.dosage_unit_measure,
      dosage_take: prescriptionDetails.dosage_take,
      dosage_to: prescriptionDetails.dosage_to,
      dosage_timeframe_take: prescriptionDetails.dosage_timeframe_take,
      dosage_timeframe_to: prescriptionDetails.dosage_timeframe_to,
      days_supply: prescriptionDetails.days_supply,
      sig_information: prescriptionDetails.sig_information,
      prescription_description: prescriptionDetails.prescription_description,
      pharmacist_id: prescriptionDetails.pharmacist_id,
      doctor_id: prescriptionDetails.doctor_id,
      order_item_id: prescriptionDetails.order_item_id || null
    };
    return prescriptionData;
  }

  public async orderInvoice(id: number) {
    const url = `sale/orders/${id}/invoice`;
    const resp: AxiosResponse = await Vue.axios({
      method: "GET",
      url
    });
    return resp.data.data;
  }

  public getLoyaltyDiscountLabel(loyaltyItem: LoyalityItem) {
    const typeDiscount = i18n
      .t(`batches.discount_manager.${loyaltyItem.points_type}`, {
        param: loyaltyItem.points_used
      })
      .toString();
    return i18n
      .t("cart_discounts.loyalty_added_product", {
        name: loyaltyItem.program_tier.loyalty_program.name,
        amount: currencyFilter(loyaltyItem.program_tier.price),
        type: typeDiscount
      })
      .toString();
  }

  public getDiscountLabel(discount: Discount, addCountable: number) {
    const discounts: Discount[] = store.getters["OrderModule/discounts"];
    const typeDiscount = i18n
      .t(
        `batches.discount_manager.${
          discount.post_tax ? "post_tax_discount" : "pre_tax_discount"
        }`
      )
      .toString();

    let description = discount.description;
    let originalDiscountAmount = null;
    if (discount.apply_type === "PERCENTAGE") {
      originalDiscountAmount = Number(
        discount.original_discount_amount
      ).toFixed(2);
    } else {
      originalDiscountAmount = Number(discount.original_discount_amount);
    }

    if (discounts.length) {
      const codename = discounts.find(d => d.id === discount.discount_id)!
        .codename;

      if (Object.values(loyaltyDiscountsCodes).includes(codename)) {
        if (addCountable === 0) {
          return i18n
            .t("cart_discounts.description_loyalty", {
              points: +discount.loyalty_points!,
              amount: currencyFilter(originalDiscountAmount),
              type: typeDiscount
            })
            .toString();
        } else {
          return i18n
            .t("cart_discounts.description_loyalty_counted", {
              points: +discount.loyalty_points!,
              amount: currencyFilter(originalDiscountAmount),
              type: typeDiscount,
              count: addCountable
            })
            .toString();
        }
      } else if (
        Object.values(overwriteDiscountCodes).includes(codename) &&
        !description
      ) {
        description = i18n.t("cart_discounts.manager_manual").toString();
      }
      if (
        discount.is_whole_order_discount &&
        discount.is_whole_order_discount === 1
      ) {
        let descriptionValue;
        if (discount.apply_type === "PERCENTAGE") {
          const discountValue =
            (Number(originalDiscountAmount) /
              Number(discount.whole_order_equivalent_discount_amount)) *
            100;
          descriptionValue = i18n
            .t("cart_discounts.manual_fixed_whole_order_description_loyalty", {
              discountValue: `${discountValue.toFixed(2)}%`,
              whole_order_discount_applied: discount.whole_order_discount_applied
                ? discount.whole_order_discount_applied.toFixed(2)
                : null
            })
            .toString();
        } else {
          const amount =
            (Number(originalDiscountAmount) /
              Number(discount.whole_order_discount_applied)) *
            100;
          descriptionValue = i18n
            .t("cart_discounts.manual_whole_order_description_loyalty", {
              amount: `${amount.toFixed(2)}%`,
              whole_order_discount_applied: `$${
                discount.whole_order_discount_applied
                  ? discount.whole_order_discount_applied.toFixed(2)
                  : null
              }`
            })
            .toString();
        }
        if (
          discount.name === "POS Per-Order Percentage Manual Discount" ||
          discount.name === "POS Per-Order Fixed Manual Discount"
        ) {
          if (description !== null) {
            discount.name = description;
          } else {
            discount.name = "Manual Discount";
          }
        }
        const discountAmount = Number(discount.pre_tax_discount);
        return `"${discount.name}" - ${typeDiscount} - ${descriptionValue} - ${
          addCountable ? addCountable + "@" : ""
        }$${truncate(discountAmount, 2)}`;
      }
    }

    const formattedAmount =
      discount.apply_type === DiscountValues.PERCENTAGE
        ? `${originalDiscountAmount}%`
        : currencyFilter(originalDiscountAmount);

    return `${!description ? discount.name : description} - ${typeDiscount} - ${
      addCountable ? addCountable + "@" : ""
    }${formattedAmount}`;
  }

  public async getDetailedOrder(orderID: number) {
    const url = `sale/orders/${orderID}/re-print-label`;
    try {
      const response = await this.axios.get(url);
      return response.data.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  public async getRefunds(query: any) {
    this.uri = `/sale/orders/refunds`;
    try {
      if (
        query["q[order.preOrder.source_eq]"] &&
        query["q[order.preOrder.source_eq]"] === "ORDER"
      ) {
        query = {
          ...query,
          transcationType: "ORDER"
        };
        delete query["q[order.preOrder.source_eq]"];
      }
      const response = await super.get({
        ...query,
        sort: query.sort || "-created_at"
      });
      return response.data.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }
  public async getRefundsOfCustomer(id: string, pagination: TablePagination) {
    this.uri = `/sale/orders/refunds`;
    const query = {
      page: pagination.currentPage,
      per_page: pagination.itemsPerPage || 10,
      customer_id: id,
      sort: "-created_at"
    };
    const response = await super.get(query);
    return response;
  }

  public async uploadPmp(orderId: number, transactionId = "") {
    try {
      const url = `/sale/orders/upload_pmp`;
      const response: AxiosResponse = await Vue.axios({
        method: "POST",
        url,
        data: {
          orderId,
          transactionId
        }
      });
      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async createOrder(payload: Order) {
    this.uri = "/sale/orders";
    const response = await super.post(payload);
    return response;
  }

  public async updateOrder(
    model: any,
    data: any,
    hasQuery: boolean = false,
    pin?: number | string
  ) {
    this.uri = "/sale/orders";
    const response = await super.put(model, data, hasQuery, pin);
    return response;
  }

  public async deleteOrder(model: Order) {
    this.uri = "/sale/orders";
    const response = await super.delete(model);
    return response;
  }
}

export const orderService: OrderService = new OrderService();
