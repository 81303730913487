import { SearchQuery } from "@/interfaces/httpQuery";
import { Location } from "@/interfaces/location";
import { RetailSettings } from "@/interfaces/retailSettings";
import { store } from "@/internal";
import forEach from "lodash/forEach";
// @ts-ignore
import toFormData from "object-to-formdata";
import HttpService from "./http.service";

class RetailSettingsService extends HttpService {
  protected defaultSort: string = "";
  protected uri: string = "/setting/retail_settings";

  protected searchableField: SearchQuery[] = [
    {
      field: "name",
      type: "contains"
    }
  ];

  public async get(): Promise<RetailSettings> {
    const response = await super.get({});
    const payload = response.data;
    return payload;
  }

  public async findByLocationId(id: number | null): Promise<RetailSettings> {
    this.uri = "/setting/retail_settings";
    const settings = await super.get({ location_id: id });
    return settings.data;
  }

  public async save(settings: RetailSettings): Promise<RetailSettings> {
    let model = { ...settings };
    if (model.rx_number === null) {
      delete model.rx_number;
    }
    if (settings.id) {
      this.query.id = settings.id;
      this.uri = `${this.uri}/${settings.id}`;
      model._method = "PUT";
    }

    if (model.default_image_product) {
      forEach(model, (val, key) => {
        if (typeof val === "boolean") {
          model[key] = +val;
        } else if (Array.isArray(val)) {
          // is payment method, should parse enabled bool to number
          forEach(val, element => (element.enabled = +element.enabled));
        }
      });
      model = toFormData({ ...model }, { indices: true });
    }
    try {
      const response = await super.post(model);
      return response;
    } finally {
      delete this.query.id;
      this.uri = "/setting/retail_settings";
    }
  }

  public async handleLoadingRetailSettingForFirsTimeForOklahoma() {
    const currentLocation: Location =
      store.getters["AuthModule/currentLocation"];
    const currentRetailSettings: RetailSettings =
      store.getters["AuthModule/currentRetailSettings"];
    if (currentLocation.state!.code && currentLocation.state!.code === "OK") {
      if (currentRetailSettings.temporary_medical_card_enabled === null) {
        currentRetailSettings.temporary_medical_card_enabled = 1;
        await this.save(currentRetailSettings);
        return currentRetailSettings;
      }
    }
    return null;
  }
}

export const retailSettingsService: RetailSettingsService = new RetailSettingsService();
