import { Customer, CustomerLimitConfig } from "@/interfaces/customer";
import { EventBus, store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { customerService } from "@/services/customer.service";
import { limitsService } from "@/services/limits.service";
import { messagesService } from "@/services/messages.service";
import { CustomerState } from "@/vuex/modules/customer/customer.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import flatMap from "lodash/flatMap";
import { ActionContext, ActionTree } from "vuex";
// tslint:disable-next-line
const toFormData = require("object-to-formdata");

type CustomerActionContext = ActionContext<CustomerState, RootState>;
type CustomerActionTree = ActionTree<CustomerState, RootState>;

const confirmCustomerAge = async (newCustomer: Customer): Promise<boolean> => {
  let confirmAge = true;
  const configOfAge = customerService.customerValidAdult(newCustomer);
  if (configOfAge.notAdult) {
    if (configOfAge.action === "warning") {
      confirmAge = !!(await customerService.infoModalValidate(
        `${i18n.tc("under_age_customer_action")} ${configOfAge.age}, ${i18n.tc(
          "under_age_customer_question"
        )}`
      ));
    } else {
      await customerService.blockModalValidate({
        msgModal: {
          title: "under_age_customer_title",
          body: `${i18n.tc("under_age_customer_action")} ${configOfAge.age}`,
          captionButton: "OK"
        }
      });
      confirmAge = false;
    }
  }

  return confirmAge;
};

export const actions: CustomerActionTree = {
  async configService(context: CustomerActionContext, route: string) {
    customerService.config(route);
    context.commit("setCustomerRouteforSave", route);
    // Set default pagination for pos Customer List
    if (route === "pos") {
      const pagination: TablePagination = {
        totalItems: 0,
        itemsPerPage: 4,
        itemsPerPageOptions: [],
        currentPage: 1,
        lastPage: 0,
        from: 1,
        to: 4
      };
      context.commit("setPagination", pagination);
    }
  },

  async setScrollPagination(
    context: CustomerActionContext,
    scrollPagination: any
  ) {
    const pagination: TablePagination = {
      totalItems: scrollPagination.total,
      itemsPerPage: 4,
      itemsPerPageOptions: [],
      currentPage: scrollPagination.current_page,
      lastPage: scrollPagination.last_page,
      from: 1,
      to: 4
    };
    context.commit("setScrollPagination", pagination);
  },

  async loadCustomers(
    context: CustomerActionContext,
    statusFilter: boolean = true
  ) {
    try {
      context.commit("setLoading", true);
      const payload: Customer[] = statusFilter
        ? await customerService.get()
        : [];
      const pagination: TablePagination = statusFilter
        ? await customerService.getPagination()
        : customerService.getResetedPagination();
      context.commit("setPagination", pagination);
      context.commit(
        "setCustomers",
        payload.map(items => ({
          ...items,
          limits: flatMap(
            customerService.setOneDailyMonthlyLimit(
              // @ts-ignore
              items.applicable_limits || items.limits
            )
          )
        }))
      );
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  savedForCheckin(context: CustomerActionContext, save: boolean) {
    context.commit("setSavedForCheckin", save);
  },

  async saveCustomer(
    context: CustomerActionContext,
    data: { customer: Customer; customerSalesLimits: CustomerLimitConfig }
  ) {
    try {
      const formData: FormData = toFormData(data.customer, { indices: true });
      let isNewCustomer = false;
      let newCustomer = null;
      if (
        data.customer.avatar &&
        data.customer.avatar.size &&
        !data.customer.avatar.name
      ) {
        formData!.set(
          "avatar",
          // @ts-ignore : It needs a type string  but we're using it a object
          data.customer.avatar,
          "picture.png"
        );
      }
      if (data.customer.customer_id) {
        if (data.customer.member_level_id === undefined) {
          formData.append("member_level_id", "");
        }
        formData.append("_method", "PUT");
        newCustomer = await customerService.put(data.customer, formData);
      } else {
        isNewCustomer = true;
        newCustomer = await customerService.post(formData);
      }
      let msg = "customer.customer_saved";
      if (
        !store.getters["CustomerModule/navigateToAddDoctor"] &&
        (store.getters["CustomerModule/savedForCheckin"] ||
          store.getters["CustomerModule/customerRouteforSave"] ===
            "check-in") &&
        newCustomer.customer_id
      ) {
        if (await confirmCustomerAge(newCustomer)) {
          if (isNewCustomer) {
            await customerService.checkInCustomer(`${newCustomer.customer_id}`);
          } else if (store.getters["CustomerModule/navigateToCheckIn"]) {
            await customerService.checkInCustomer(`${newCustomer.customer_id}`);
          }
          customerService.resetPagination();
          msg = "customer.customer_saved_checked";
        }
        if (isNewCustomer) {
          context.dispatch(
            "RouterModule/go",
            {
              name: "check-in",
              params: { customerId: newCustomer.customer_id }
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "RouterModule/go",
            { name: "check-in" },
            { root: true }
          );
        }
      } else if (store.getters["CustomerModule/navigateToAddDoctor"]) {
        context.dispatch(
          "RouterModule/go",
          {
            name: "doctors-add",
            query: {
              custId: data.customer.customer_id! || newCustomer.customer_id!
            }
          },
          { root: true }
        );
      } else if (
        store.getters["CustomerModule/customerRouteforSave"] === "customers"
      ) {
        context.dispatch(
          "RouterModule/go",
          { name: "customers-list" },
          { root: true }
        );
      } else if (
        store.getters["CustomerModule/customerRouteforSave"] === "call-in"
      ) {
        if (
          isNewCustomer &&
          (await confirmCustomerAge(newCustomer)) &&
          (await confirmCustomerAge(newCustomer))
        ) {
          EventBus.$emit("savedCustomerForCallIn", newCustomer);
        } else {
          store.dispatch("RouterModule/back");
        }
      } else {
        context.dispatch(
          "RouterModule/go",
          { name: "customers-management" },
          { root: true }
        );
      }
      await limitsService.saveLimitsByCustomer(newCustomer.customer_id, {
        ...data.customerSalesLimits,
        customer_id: newCustomer.customer_id
      });

      if (msg) {
        messagesService.renderSuccessMessage(msg);
      }
      context.commit("setSavedForCheckin", false);
      context.commit("setNavigateToCheckIn", false);
      context.commit("setNavigateToAddDoctor", false);
      context.dispatch("setCustomer", null);
    } catch (e) {
      if (e.response === undefined) {
        /// 413 error code
        messagesService.renderErrorMessage(i18n.t("template_server_error"));
      } else {
        messagesService.renderErrorMessage(e);
      }
    }
  },

  async filterCustomers(context: CustomerActionContext, filters: object) {
    try {
      customerService.sortQuery(filters);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  setCustomer(context: CustomerActionContext, customer: Customer | null) {
    context.commit("setCurrentCustomer", customer);
  },

  async findCustomer(context: CustomerActionContext, id: number) {
    try {
      const customer: Customer | null = await customerService.find(Number(id));
      context.dispatch("setCustomer", customer);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async findCustomerProfile(
    context: CustomerActionContext,
    data: { id: string; redirect: boolean }
  ) {
    try {
      const customer: Customer | null = await customerService.findById(data.id);
      context.dispatch("setCustomer", customer);
    } catch (error) {
      if (data.redirect) {
        store.dispatch("RouterModule/errorRedirect", {
          location: { name: "customers-list" },
          error
        });
      } else {
        messagesService.renderErrorMessage(error);
      }
    }
  },

  async searchToCustomerList(context: CustomerActionContext, value: string) {
    try {
      const search = customerService.searchEvent();
      search(value);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async checkInCustomer(context: CustomerActionContext, customerId: string) {
    const goCheckIn =
      store.getters["AuthModule/currentLocation"].location_type ===
      "RETAIL_MEDICAL"
        ? await customerService.validationCheckin(
            store.getters["CustomerModule/currentCustomer"]
          )
        : true;
    if (goCheckIn) {
      try {
        await customerService.checkInCustomer(customerId);
        context.dispatch(
          "RouterModule/go",
          { name: "check-in", params: { customerId } },
          { root: true }
        );
        customerService.resetPagination();
      } catch (e) {
        messagesService.renderErrorMessage(e);
        context.dispatch("loadCustomers", true);
      }
    }
  },

  async checkOutCustomer(context: CustomerActionContext, id: number) {
    try {
      await customerService.checkOutCustomer(id);
      context.dispatch("setCustomer", null);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async saveRecreationalCustomer(
    context: CustomerActionContext,
    data: { customer: Customer; reloadCustomers: boolean }
  ): Promise<Customer | null> {
    try {
      const formData: FormData = toFormData(data.customer, { indices: true });
      const resp: Customer = await customerService.post(formData);
      if (resp.customer_id) {
        const checked = await customerService.checkInCustomer(resp.customer_id);
        if (data.reloadCustomers) {
          context.dispatch("loadCustomers", true);
        }
        return checked;
      } else {
        return null;
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  },
  clearCustomerList(context: CustomerActionContext) {
    context.commit("setCustomers", []);
  }
};
